import React, { useState, useEffect } from "react"

const initialStoreState = {
  isInitializing: true,
}

export const StoreContext = React.createContext({
  store: initialStoreState
})

const StoreContextProvider = ({ children }) => {
  const [store, setStore] = useState(initialStoreState)

  useEffect(() => {
    const initializeCheckout = async () => {
      console.log("INITIALIZE")
    }

    initializeCheckout()
  }, [])

  return (
    <StoreContext.Provider
      value={{
        store
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}

export default StoreContextProvider
